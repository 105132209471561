import axios from 'axios'

axios.defaults.withCredentials = true// 让ajax携带cookie
axios.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded'
axios.defaults.headers.common['x-requested-with'] = 'XMLHttpRequest'

// 创建 axios 实例
const service = axios.create({
    baseURL: '/',
    timeout: 20000, // 请求超时时间
})

const err = error => {
    return Promise.reject(error)
}

// request interceptor
service.interceptors.request.use(config => {
    config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
    return config
}, err)

// response interceptor
service.interceptors.response.use(response => {
    return response.data
}, err)

export { service as axios }
