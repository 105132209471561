import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
    mode: 'history',
    routes: [{
            path: '/',
            name: 'index',
            meta: { title: '会员中心' },
            component: () =>
                import ('@/view/index/index.vue')
        },
        {
            path: '/goods',
            name: 'goods',
            meta: { title: '会员中心' },
            component: () =>
                import ('@/view/goods/goods.vue')
        },
        {
            path: '/newgoods',
            name: 'newgoods',
            meta: { title: '会员中心' },
            component: () =>
                import ('@/view/goods/newGoods.vue')
        },
        {
            path: '/goodsList',
            name: 'goodsList',
            meta: { title: '会员列表' },
            component: () =>
                import ('@/view/goodsList/goodsList.vue')
        },
        {
            path: '/order',
            name: 'order',
            meta: { title: '购买记录' },
            component: () =>
                import ('@/view/order/order.vue')
        },
        {
            path: '/activation',
            name: 'activation',
            meta: { title: '兑换激活码' },
            component: () =>
                import ('@/view/activation/activation.vue')
        },
        {
            path: '/pcIndex',
            name: 'pcIndex',
            meta: { title: '会员中心' },
            component: () =>
                import ('@/view/PC/index/index.vue')
        },
        {
            path: '/agreement',
            name: 'agreement',
            meta: { title: '用户协议' },
            component: () =>
                import ('@/view/goods/agreement.vue')
        },
        {
            path: '/error',
            name: 'error',
            meta: { title: '用户协议' },
            component: () =>
                import ('@/view/error/error.vue')
        }
    ]
})