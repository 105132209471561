<template>
  <div id="app">

      <router-view/>
  </div>
</template>


<script>

export default {
  name: 'app',
}
</script>

<style>
  html {
    background-color: white;
  }

  body {
    margin: 0;
  }
  @font-face {
    font-family: "SourceHanSansCN";
    src: url("../font/SourceHanSansCN-Regular.otf");
  }
</style>
