import { axios } from '../util/request'
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded'
axios.defaults.headers.common['x-requested-with'] = 'XMLHttpRequest'
axios.defaults.headers.common['x-forwarded-host'] = 'https://zsdy-shop-web-api-daily.shui12366.com';
const http = process.env.NODE_ENV === 'development' ? '/api' : '/api';
const BaseApi = process.env.NODE_ENV === 'development' ? '/BaseApi' : '/BaseApi';

export function list(data) {
    return axios({
        url: http + '/app/list',
        method: 'POST',
        params: data
    })
}
//获取用户信息
export const getUserInfo = () => {
    return axios({
        url: http + '/user/info?r=' + Math.random(),
        method: 'get',
    })
}
export function detail(data) {
    return axios({
        url: http + '/app/detail',
        method: 'GET',
        params: data
    })
}
export function sendToken(data) {
    return axios({
        url: http + '/sso/setcookie',
        method: 'GET',
        params: data
    })
}

export function goodsList(data) {
    return axios({
        url: http + '/goods/list',
        method: 'POST',
        params: data
    })
}
export function goodsDetail(data) {
    return axios({
        url: http + '/goods/detail',
        method: 'POST',
        params: data
    })
}
export function privilegeList(data) {
    return axios({
        url: http + '/privilege/list',
        method: 'POST',
        params: data
    })
}
export function orderList(data) {
    return axios({
        url: http + '/order/list',
        method: 'POST',
        params: data
    })
}
export function deleshan(data) {
    return axios({
        url: http + '/order/delete',
        method: 'POST',
        data,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    })
}
export function invoices(data) {
    return axios({
        url: http + '/invoices/save',
        method: 'POST',
        params: data
    })
}
export function buy(data) {
    return axios({
        url: http + '/order/buyByCode',
        method: 'POST',
        params: data
    })
}
export function pay(data) {
    return axios({
        url: http + '/order/pay/' + data,
        method: 'POST'
    })
}
export function orderStatus(data) {
    return axios({
        url: http + '/order/status' + data,
        method: 'GET',
    })
}
export function use(data) {
    return axios({
        url: http + '/licenseKey/use',
        method: 'POST',
        params: data
    })
}
//手机号登录
export const bindMoibleByCode = (data) => {
        return axios.request({
            method: 'post',
            url: BaseApi + '/wxmp/bindMoibleByCode',
            params: data,
        });
    }
    //获取验证码
export function sendCode(data) {
    return axios({
        url: BaseApi + '/ajax/sendCode',
        method: 'post',
        params: data,
    })
}
export function sbwApi(data) {
    return axios({
        url: '/sbwApi/wx/miniapp/generateScheme',
        method: 'POST',
        params: data,
        //data:data,

    })
}