import Vue from 'vue'
import App from './App.vue'
import router from './router'
import 'swiper/dist/css/swiper.min.css';
import  Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(Element)
import Vant from 'vant';
import 'vant/lib/index.css';
import { Overlay } from 'vant';
import { Field } from 'vant';
import { Tab, Tabs } from 'vant';
import moment from 'moment'
import { Toast } from 'vant';
import { Dialog } from 'vant';
import Meta from 'vue-meta';
Vue.use(Meta)
Vue.use(Toast);
Vue.use(Dialog);
Vue.filter('dateformat', function(dataStr, pattern = 'YYYY-MM-DD') {
    return moment(dataStr).format(pattern)
})
Vue.use(Tab);
Vue.use(Tabs)
Vue.use(Field);
Vue.use(Overlay);
Vue.use(Vant);

Vue.config.productionTip = false;
import VueCookies from 'vue-cookies'
Vue.use(VueCookies)
 import { sendToken } from '@/api/member'
router.beforeEach((to,from,next) => {
    if(to.meta.title){
        document.title = to.meta.title
    }
    let  token = to.query.token;
    //如果存在token参数
    if(token){
        let newObj = {
            session_id:to.query.token,
            ticket:to.query.token,
        }
        if(to.query.expiresIn)newObj.expires_in = to.query.expiresIn
        sendToken(newObj).then((res) => {
            if(res.indexOf('200')!=-1){
                // setTimeout(() => {
                    
                // }, 10000);
                next()
               
            }else{
                window.console.log(res)
            }
            window.console.log(res)
            //
        }).catch(err => {
            window.console.log(err)
        })
    }else{
        next()
      
    }
    
    // getUserInfo().then(res => {
    //     if (res.code === 401) {
    //         let url = "/api/snslogin/wxgzh?redirect_uri=" + location.href;
    //         window.location.replace(url)
    //     }
    //
    //     sessionStorage.setItem("userInfo", JSON.stringify(res.data))
    // });
})
new Vue({
    render: h => h(App),
    router,
}).$mount('#app')
